import request from './request'

const api = {
	// 获取列表
	lists: '/partydynamics/lists',
	// 添加
	add: '/partydynamics/add',
	// 删除
	dele: '/partydynamics/del',
	// 编辑
	edit: '/partydynamics/update',
	// 详情
	info: '/partydynamics/read',
	// 增加积分
	incPoints: '/partydynamics/incPoints',
	// 减少积分
	decPoints: '/partydynamics/decPoints',
	// 村
	cunlist: '/village/lists',
}

export {
	request,
	api
}
