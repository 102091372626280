<template>
	<div class="bg-Hight">
		<!-- 筛选 -->
		<div class="crud">
			<ul class="crud-list">
				<li>
					<el-button size="mini" @click="getList">刷新</el-button>
				</li>
				<li v-if="$store.state.villageId>0" v-permission="['dynamicsdele']">
					<el-button @click="alldele" class="el-white" size="mini" type="danger">删除</el-button>
				</li>
				<li>
					<el-input @clear="reset" type="text" clearable v-model="form.keyword" placeholder="请输入姓名">
					</el-input>
				</li>
				<li v-if="$store.state.villageId==0">
					<el-select clearable filterable v-model="form.village_id" placeholder="请选择村">
						<el-option v-for="item in cunlist" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
				</li>
				<li>
					<el-select clearable v-model="form.type" placeholder="请选择状态">
						<el-option label="全部" :value="0"></el-option>
						<el-option label="日常行为" :value="1"></el-option>
						<el-option label="民主评议" :value="2"></el-option>
						<el-option label="突出贡献" :value="3"></el-option>
						<el-option label="建言献策" :value="4"></el-option>
					</el-select>
				</li>
				<li>
					<el-button @click="search" color="#00ad79" class="el-white" style="color: white" size="mini">搜索
					</el-button>
				</li>
				<li>
					<el-button @click="reset" size="mini" class="el-white" type="danger">清空</el-button>
				</li>
			</ul>
		</div>
		<!-- 表格列表 -->
		<div class="table">
			<el-table element-loading-text="加载中..." v-loading="tableloading" :max-height="maxheight" border
				:data="tableData" @selection-change="handleSelectionChange">
				<el-table-column type="selection" width="80" />
				<el-table-column type="index" label="序号" width="80" />
				<el-table-column property="village_name" label="村" />
				<el-table-column property="uname" label="姓名" />
				<el-table-column property="content" label="发布内容">
					<template #default="scope">
						<el-popover placement="top-start" title=" " :width="200" trigger="hover"
							:content="scope.row.content">
							<template #reference>
								<div class="pubcontent">{{scope.row.content}}</div>
							</template>
						</el-popover>
					</template>
				</el-table-column>
				<el-table-column property="name" label="图片">
					<template #default="scope">
						<div class="dialog-up">
							<div v-if="scope.row.imgpath" style="cursor: pointer;"
								@click="seeImg(ImgList(scope.row.imgpath))"
								v-for="(item,index) in ImgList(scope.row.imgpath).slice(0,1)" :key="index">
								<el-image style="width: 80px; height: 80px;" :src="item" fit="fill">
								</el-image>
								<p>共{{ImgList(scope.row.imgpath).length}}张点击查看</p>
							</div>
							<div class="zanwu" v-else>暂无</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column property="name" label="视频">
					<template #default="scope">
						<video v-if="scope.row.video" width="80" height="80" controls>
							<source :src="scope.row.video" type="video/mp4">
						</video>
					</template>
				</el-table-column>
				<el-table-column property="integral" label="增加的积分" />
				<el-table-column property="create_time" label="创建时间" />
				<el-table-column property="address" label="操作" width="280">
					<template #default="scope">
						<el-button v-permission="['dynamicsintegral']" @click="integral(scope.row.id,'增加积分')"
							size="mini">增加积分
						</el-button>
						<el-button v-permission="['dynamicsdele']" size="mini" @click="dele(scope.row.id)">删除
						</el-button>
						<el-button v-permission="['dynamicsintegral']" @click="integral(scope.row.id,'减少积分')"
							size="mini">减少积分
						</el-button>
					</template>
				</el-table-column>
			</el-table>
			<!--分页 -->
			<div class="pages">
				<el-pagination background v-model="form.page" :page-sizes="[15, 20, 30, 40,50,100]"
					:page-size="form.limit" layout="sizes, prev, pager, next, jumper" :total="total"
					@size-change="handleSizeChange" @current-change="handleCurrentChange">
				</el-pagination>
			</div>
		</div>
		<!-- 积分 -->
		<integral ref="integral" @SubmitForm="getList"></integral>
		<!-- 全部图片 -->
		<seeImg ref="seeImg"></seeImg>
	</div>
</template>

<script>
	import {
		ElMessageBox,
		ElMessage
	} from 'element-plus'
	import integral from './components/integral.vue'
	import seeImg from '@/components/seeImg.vue'
	import {
		request,
		api
	} from "@/api/dynamics";
	export default {
		components: {
			integral,
			seeImg
		},
		data() {
			return {
				maxheight: document.documentElement.clientHeight - 232 + 'px',
				total: 0,
				form: {
					keyword: '',
					page: 1,
					limit: 15,
					type: 0,
					village_id: ''
				},
				tableData: [],
				idlist: [],
				cunlist: []
			}
		},
		created() {
			this.getList()
			this.getcun()
		},
		methods: {
			// 获取列表
			getcun() {
				this.$http.post(api.cunlist, {
						page: 1,
						limit: 999,
					})
					.then((res) => {
						if (res.code == 200) {
							this.cunlist = res.data.data
						}
					})
			},
			// 查看图片
			seeImg(list) {
				this.$refs.seeImg.show(list)
			},
			// 图片转化
			ImgList(val) {
				if (val) {
					return val.split(",")
				} else {
					return []
				}
			},
			handleSizeChange(e) {
				this.form.limit = e
				this.getList()
			},
			// 分页
			handleCurrentChange(e) {
				this.form.page = e
				this.getList()
			},
			// 清空
			reset() {
				for (let key in this.form) {
					this.form[key] = ''
				}
				this.form.limit = 15
				this.form.page = 1
				this.form.type = 0
				this.getList()
			},
			// 搜索
			search() {
				this.form.page = 1
				this.getList()
			},
			// 表格全选
			handleSelectionChange(e) {
				this.idlist = []
				e.forEach(item => {
					this.idlist.push(item.id)
				})
			},
			// 获取列表
			getList() {
				this.tableloading = true
				this.$http.post(api.lists, this.form)
					.then((res) => {
						if (res.code == 200) {
							this.tableData = res.data.data
							this.total = res.data.count
							this.tableloading = false
						}
					})
			},
			// 积分
			integral(id, val) {
				this.$refs.integral.show(id, val)
			},
			// 批量删除
			alldele() {
				if (this.idlist.length == 0) {
					ElMessage({
						message: '请选择需要删除数据',
						type: 'warning',
					})
				} else {
					ElMessageBox.confirm(
							'此操作将永久删除?',
							'提示', {
								confirmButtonText: '确定',
								cancelButtonText: '取消',
								type: 'warning',
							}
						)
						.then(() => {
							this.$http.post(api.dele, {
									id: this.idlist.join(',')
								})
								.then((res) => {
									if (res.code == 200) {
										ElMessage({
											type: 'success',
											message: '删除成功',
										})
										this.form.page = 1
										this.getList()
									}
								})
						})
						.catch(() => {
							// 取消
						})
				}
			},
			// 删除
			dele(id) {
				ElMessageBox.confirm(
						'此操作将永久删除?',
						'提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning',
						}
					)
					.then(() => {
						this.$http.post(api.dele, {
								id: id
							})
							.then((res) => {
								if (res.code == 200) {
									ElMessage({
										type: 'success',
										message: '删除成功',
									})
									this.form.page = 1
									this.getList()
								}
							})
					})
					.catch(() => {
						// 取消
					})
			}
		}
	}
</script>

<style scoped lang="scss">
	.Draggable {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
	}

	.dialog-up {
		.top {
			font-size: 12px;
			position: absolute;
			top: 0;
			height: 20px;
			line-height: 20px;
			right: 0;

			i {
				padding: 3px;
				cursor: pointer;
				font-size: 14px;
				margin-left: 5px;
				color: #FFFFFF;
				background: rgba(0, 0, 0, 0.5);
			}
		}
	}

	.dialog-up-left {
		float: left;
		margin-left: 20px;
	}

	.upload-li {
		font-size: 12px;
		display: flex;
		height: 20px;
		line-height: 20px;
	}

	.upload-li p {
		cursor: pointer;
		color: #999999;
		margin-left: 20px;
	}

	.upload-li p:hover {
		color: #00ad79;
	}

	.dialog-title {
		margin: 40px 0;
		font-size: 16px;
	}

	.dialog-img-box {
		cursor: move;
		position: relative;
		height: 80px;
		overflow: hidden;
		width: 80px;
		margin-right: 10px;
		border: 1px solid #dcdde0;
		border-radius: 4px;
		text-align: center;
		margin-bottom: 10px;
	}

	.dialog-img-box p {
		font-size: 12px;
		width: 80px;
		position: absolute;
		bottom: 0;
		height: 20px;
		line-height: 20px;
		left: 0;
		text-align: center;
		background: rgba(0, 0, 0, 0.5);
		color: #FFFFFF;
		text-overflow: -o-ellipsis-lastline;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 1;
		line-clamp: 1;
		-webkit-box-orient: vertical;
	}
</style>